import { Component, OnInit, OnDestroy,ViewChild} from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, { SwiperOptions, Swiper, Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
	config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 31,
    navigation: true,
	loop: true,
    centeredSlides: true,
    initialSlide:1,
    pagination: false,
    scrollbar: false,
    breakpoints: {
		767: {
		  slidesPerView: 'auto',
		  spaceBetween: 20,
		},
	}
	
  };
	private ngUnsubscribe = new Subject<void>();

loginState=null;
vidplayed=false;
Profile:any|[];
Content:any|[];
token:any|[];
  constructor(public PS: ProfileService, public AS: AssetsService, public CS:ContentManagerService,public route: ActivatedRoute, private router: Router) {  }

  ngOnInit(): void {
	  this.loginState=null;
	  this.loginState=this.route.snapshot.paramMap.get('loginstate');
	  if(this.loginState=="reset"){
		  localStorage.removeItem("vid")
		  localStorage.setItem("vid","reset");
		  this.router.navigate(['home']);
	  }
	  if(localStorage.getItem("vid")=="played"){
		  this.CS.vidOpen=false;
	  }else{
		  this.CS.vidOpen=true;
	  }
	  if(this.loginState=="logout"){
	  	  this.PS.logout();
	  }
	 // this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Profile = data; console.log(this.Profile)});
	  this.PS.getToken().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.token = data; console.log(this.token)});
	  this.CS.getContent().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Content=data; console.log('content', this.Content);
	  });
	  if(this.loginState=="login"){
		  this.CS.loginState="begin";
		  this.CS.loginOpen=true;
	  }else{
  		  this.CS.loginState="begin";
		  this.CS.loginOpen=false;

		  if(localStorage.getItem("vid")=="played"){
			  this.vidplayed=true;
			  this.CS.vidOpen=false;
		  }else{
			 this.CS.vidOpen=true;
		  }
	  }
  }
	launchVid(){
		this.CS.vidOpen=true;
	}
   ngOnDestroy()  {
	this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
  }
}
