import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';


@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
	private ngUnsubscribe = new Subject<void>();
	Profile: any |[];
	select_open=false;
	showPassword=false;
	verifying=false;
	pwnumb=false;
	pwchar=false;
	pwsymb=false;
	pwcap=false;
	notpw=false;
	pw_match_fail=false;
	pwords={
		repeat:null,
		newpw:null,
		oldpw:null,
	}

  constructor(public PS:ProfileService, public CS: ContentManagerService) { }

  ngOnInit(): void {
	  this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Profile = data;});
  }
	ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	toggleSelectOpen(){
		this.select_open=!this.select_open;
	}
	select_option(val){
		this.Profile.account_type=val;
	}
	keyPressNumbers(event) {
		var charCode = (event.which) ? event.which : event.keyCode;
		// Only Numbers 0-9
		if ((charCode < 48 || charCode > 57)) {
		  event.preventDefault();
		  return false;
		} else {
		  return true;
		}
	  }	
	
	passwordCheck(e){
		//var charCode = (event.which) ? event.which : event.keyCode;
		  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		this.pwsymb=specialChars.test(this.pwords.newpw);  
		this.pwnumb=/\d/.test(this.pwords.newpw);
		this.pwchar=this.pwords.newpw==null? false : this.pwords.newpw?.length>=8? true : false;
		this.pwcap=/[A-Z]/.test(this.pwords.newpw);
		
	}
	verifyPw(){
		if(this.pwsymb && this.pwnumb && this.pwchar && this.pwcap && (this.pwords.newpw==this.pwords.repeat)){
			return true;
		}else{
			return false;
		}
		
	}
	checkPw(){
		this.notpw= this.pwords.oldpw!==this.Profile.password;
	}
	chedkMatch(){
		this.verifying=true;
		this.pw_match_fail=(this.pwords.newpw!==this.pwords.repeat);
	}
	submitProfile(prf, frm){
		console.log("form", frm);
		console.log('profile',prf);
		this.PS.edit_submitted=true;
		if(this.pwords.newpw!==this.pwords.repeat){
			this.pw_match_fail=true;
		}else if(frm.valid){
			if(this.PS.edit_state=="pw"){
				prf.old_password=this.pwords.oldpw;
				prf.new_password=this.pwords.newpw;
			}else if(this.PS.edit_state=="eml"){
				prf.new_email=prf.email;
			}
			console.log(prf);
			var id = prf.profile_id;
			var dt=[prf];
			var json_data = [];
			json_data.push([prf]);

				
			
				this.PS.updateProfile(id,prf).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
				  (response) => {
					  console.log('response', response);
					  var errcheck = JSON.stringify(response);

					  if(errcheck.includes('Unknown error')|| response[0]=='There was en error' ){
						  this.CS.alert_message="We've encountered a submission error.  Please try again or reach out to customer support.";
						  this.CS.showAlert=true;
					  }else if(response[0] == "new_email"){
						  this.PS.eml_in_use=true;
					   }else if(response[0] == "password"){
						  this.notpw=true;
					  }else if(response[0] !== "password"){
						  console.log('success');
						  //this.resetProfile();
						  this.PS.closeModals();
					  }
				  }
				);
				
				this.PS.edit_submitted=false;
			}

	}


}
