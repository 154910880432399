<app-asset-preview></app-asset-preview>
<section class="section-search">
	<div class="shell">
		<div class="search">
			<form action="?" method="get">
				<label for="search-assets" class="hidden">Search</label>

				<input type="search" name="search-assets" id="search-assets" [(ngModel)]="AS.assetSearch" placeholder="Search assets" class="search__field">

				<button type="submit" value="GO" class="search__btn">
					<img src="assets/images/svg/search--large.svg" alt="">
				</button>

				<button (click)="AS.assetSearch=null" class="search__exit">
					<img src="assets/images/svg/exit-button.svg" alt="">
				</button>
			</form>
		</div><!-- /.search -->
	</div><!-- /.shell -->
</section><!-- /.section-search -->

<section class="section-filters">
	<div class="shell">
		<h3>Customize your search:</h3>

		<div class="cols">
			<div class="col col--1of3">
				<div class="select" [ngClass]="{'is-open':select_open_network}"
					 (click)="toggleSelectOpen('network');" >
					<div class="select__head">
						<p>{{ AS.current_network ?  'Network: '+AS.current_network  : "By Network" }}</p>
					</div><!-- /.select__head -->

					<div class="select__list" (mouseleave)="toggleSelectOpen('network');">
						<ul>
							<li *ngIf="AS.current_network" (click)="clear_current('network')">Clear Filter</li>
							<li *ngFor="let ntwk of Networks | statusFilter: 'asset_flag': '1' | sortBy: 'asc' : 'network_full';" (click)="select_option('network',ntwk.network_full)">{{ntwk.network_full}}</li>
						</ul>
					</div><!-- /.select__list -->
				</div><!-- /.select -->
			</div><!-- /.col-/-1of4 -->
			<div class="col col--1of3">
				<div class="select" [ngClass]="{'is-open':select_open_campaign}"
					 (click)="toggleSelectOpen('campaign');" >
					<div class="select__head">
						<p>{{ AS.current_campaign ?  'Campaign: '+AS.current_campaign  : "By Campaign" }}</p>
					</div><!-- /.select__head -->

					<div class="select__list" (mouseleave)="toggleSelectOpen('campaign');">
						<ul>
							<li *ngIf="AS.current_campaign" (click)="clear_current('campaign')">Clear Filter</li>
							<li *ngFor="let campaign of Campaigns | sortBy: 'asc' : 'campaign_name'" (click)="select_option('campaign',campaign.campaign_name)">{{campaign.campaign_name}}</li>
						</ul>
					</div><!-- /.select__list -->
				</div><!-- /.select -->
			</div><!-- /.col-/-1of4 -->
			<div class="col col--1of3">
				<div class="select" [ngClass]="{'is-open':select_open_title}"
					 (click)="toggleSelectOpen('title');" >
					<div class="select__head">
						<p>{{ AS.current_title ?  'Title: '+ AS.current_title  : "By Title" }}</p>
					</div><!-- /.select__head -->

					<div class="select__list" (mouseleave)="toggleSelectOpen('title');">
						<ul>
							<li *ngIf="AS.current_title" (click)="clear_current('title')">Clear Filter</li>

							<li *ngFor="let title of Assets | sortBy: 'asc' : 'asset_name' " (click)="select_option('title',title.asset_name)">{{title.asset_name}}</li>
						</ul>
					</div><!-- /.select__list -->
				</div><!-- /.select -->
			</div><!-- /.col-/-1of4 -->
			<div class="col col--1of3">
				<div class="select" [ngClass]="{'is-open':select_open_type}"
					 (click)="toggleSelectOpen('type');" >
					<div class="select__head">
						<p>{{ AS.current_type ?  'Type: '+AS.current_type  : "By Asset Type" }}</p>
					</div><!-- /.select__head -->

					<div class="select__list" (mouseleave)="toggleSelectOpen('type');">
						<ul>
							<li *ngIf="AS.current_type" (click)="clear_current('type')">Clear Filter</li>
							<li *ngFor="let tp of Types | sortBy: 'asc' : 'asset_type'" (click)="select_option('type',tp.asset_type)">{{tp.asset_type}}</li>
						</ul>
					</div><!-- /.select__list -->
				</div><!-- /.select -->
			</div><!-- /.col-/-1of4 -->
			<div class="col col--1of3">
				<div class="select" [ngClass]="{'is-open':select_open_tag}"
					 (click)="toggleSelectOpen('tag');" >
					<div class="select__head">
						<p>{{ AS.current_tag ?  'Tag: '+AS.current_tag  : "By Asset Tag" }}</p>
					</div><!-- /.select__head -->

					<div class="select__list" (mouseleave)="toggleSelectOpen('tag');">
						<ul>
							<li *ngIf="AS.current_tag" (click)="clear_current('tag')">Clear Filter</li>
							<li *ngFor="let tg of Tags | sortBy: 'asc' : 'tag'" (click)="select_option('tag',tg.tag)">{{tg.tag}}</li>
						</ul>
					</div><!-- /.select__list -->
				</div><!-- /.select -->
			</div><!-- /.col-/-1of4 -->
			<div class="col col--1of3">
					<a (click)="clearAll();" class="btn">Clear All Filters</a>
			</div>
			
		</div><!-- /.cols -->

	</div><!-- /.shell -->
</section><!-- /.section-filters -->

<section class="section-utilities">
	<div class="shell">
		<div class="section__inner">
			<div class="section__tags">
				<p class="section__info--alt"><strong>{{FilteredAssets?.length}}</strong> results</p>

			</div><!-- /.section__tags -->

			<div class="section__filters">
				<div class="section__filter">
					<h4>Sort:</h4>

					<div class="select select--alt" [ngClass]="{'is-open':select_open_sort}" (click)="toggleSelectOpen('sort');">
						<div class="select__head">
							<h4>{{ getSortLabel(AS.current_sort) ?  getSortLabel(AS.current_sort)+" "+direction  : "Select" }}</h4>
						</div><!-- /.select__head -->

						<div class="select__list" (mouseleave)="toggleSelectOpen('sort');">
							<ul>
								<li *ngFor="let itm of sorts" (click)="select_option('sort',itm)">{{itm.label}}</li>
							</ul>
						</div><!-- /.select__list -->
					</div><!-- /.select -->
				</div><!-- /.section__filter -->
			</div><!-- /.section__filter -->
		</div><!-- /.section__inner -->
	</div><!-- /.shell -->
</section><!-- /.section-utilities -->

<section class="section-assets">
	<div class="shell shell--size-1">


		<div class="section__body">
			<div class="no-results" *ngIf="FilteredAssets?.length==0">
				<img src="assets/images/svg/assets.svg" alt="">
				<p>No assets were found matching your search criteria. Enter different keywords or remove search filters and try again.</p>
				<a (click)="clearAll();" class="link section__link">Reset Search</a>
			</div>
			<div class="assets">
			  
				<div class="asset" *ngFor="let asst of FilteredAssets |
										   searchFilter:AS.assetSearch | 
										   sortBy: reverse : AS.current_sort " 
					 [ngClass]="{'is-open': asst.expand}">
				<ng-container #filteredAssets>
			
					<div class="asset__aside">
						<figure class="asset__image">
							<img src="{{asst.thumb ? asst.thumb : CS.default_img}}" alt="{{asst.asset_name}}" width="195" height="110">
						</figure><!-- /.asset__image -->
						<ng-container *ngIf="asst.thumb">
							<a (click)="previewModal(asst)" class="btn btn--alt asset__preview-btn">Preview</a>
						</ng-container>
					</div><!-- /.asset__aside -->

					<div class="asset__main" >
						<header class="asset__head" (click)="toggleAsset(asst);">
							<p *ngFor="let net of asst.networks" ><strong>NETWORK: </strong>{{net.network_full}}</p>
						</header><!-- /.asset__head -->

						<div class="asset__body">
							<div class="asset__row asset__row--toggle">
								<div class="asset__col asset__col--size-1">
									<div class="asset__entry">
										<h3>{{asst.asset_name}}</h3>

										<p>{{asst.description}}</p>
										<p class="asset--contains"><strong>Files: </strong>{{asst.contains}}</p>
									</div><!-- /.asset__entry -->
								</div><!-- /.asset__col -->

								<div class="asset__col asset__col--size-2">
									<div class="asset__info">
										<p *ngFor="let camp of asst.campaign"><strong>Campaign:</strong> {{camp.campaign_name}}</p>
									</div><!-- /.asset__info -->
								</div><!-- /.asset__col asset__col-/-size-2 -->
							</div><!-- /.asset__row -->

							<div class="asset__row asset__row--hidden" *ngIf="asst.program">
								<div class="asset__col asset__col--1of2">
									<div class="asset__info">
										<p>Program: {{asst.program}}</p>
									</div><!-- /.asset__info -->
								</div><!-- /.asset__col asset__col-/-1of2 -->

								<div class="asset__col asset__col--1of2">
									<div class="asset__info">
										<p>Last Updated: {{asst.updated }}</p>
									</div><!-- /.asset__info -->
								</div><!-- /.asset__col asset__col-/-1of2 -->
							</div><!-- /.asset__row asset__row-/-hidden -->

							<div class="asset__row asset__row--hidden">
								<div class="asset__col asset__col--1of2">
									<div class="asset__info">
										<p>Tags: <span *ngFor="let tg of asst.tags">{{tg.tag}}, </span> Active</p>
									</div><!-- /.asset__info -->
								</div><!-- /.asset__col asset__col-/-1of2 -->

								<div class="asset__col asset__col--1of2">
									<div class="asset__info">
										<p>Asset Window: {{asst.flight_copy}}</p>
									</div><!-- /.asset__info -->
								</div><!-- /.asset__col asset__col-/-1of2 -->
							</div><!-- /.asset__row asset__row-/-hidden -->

							<div class="asset__row">
								<div class="asset__col asset__col--size-1">
									<div class="asset__actions">
									<!--<a (click)=usageGuide(asst.usage_guide); class="link">Usage Guidelines</a>-->
									<a  class="link" routerLink="/asset-queue" *ngIf="asst.Status=='IN-QUEUE'">Already In Queue</a>
									<a  class="link" routerLink="/my-activity" *ngIf="asst.Status=='DOWNLOADED'">Previously Downloaded</a>

									<a (click)="addtoQ(asst.asset_id)" class="btn asset__btn" *ngIf="asst.Status!=='IN-QUEUE' && asst.Status!=='DOWNLOADED'" >Add to Queue</a>
									</div><!-- /.asset__actions -->
								</div><!-- /.asset__col -->

								<div class="asset__col asset__col--size-2">
									<div class="asset__info">
										<p>File type: {{asst.asset_type}}</p>
									</div><!-- /.asset__info -->
								</div><!-- /.asset__col asset__col-/-size-2 -->
							</div><!-- /.asset__row -->
						</div><!-- /.asset__body -->
					</div><!-- /.asset__main -->
			  </ng-container>
				</div><!-- /.asset -->
			</div><!-- /.assets -->
		</div><!-- /.section__body -->
	</div><!-- /.shell -->
</section><!-- /.section-assets -->
