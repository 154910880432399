<app-download-success></app-download-success>
<app-alert></app-alert>
		<section class="section-headline">
			<div class="shell shell--size-3">
				<h2>My Activity</h2>

				<div class="tabs js-tabs">
					<nav class="tabs__nav">
						<ul>
							<li [ngClass]="{'is-current' :current_tab=='history'}">
								<a (click)="current_tab='history'">Asset History</a>
							</li>

							<li [ngClass]="{'is-current':current_tab=='custom'}" *ngIf="Customizations?.length>0">
								<a (click)="current_tab='custom'">Submitted Customizations </a>
							</li>
						</ul>
					</nav><!-- /.tabs__nav -->
				</div><!-- /.tabs js-tabs -->
			</div><!-- /.shell -->
		</section><!-- /.section-headline -->

		<section class="section-tabs">
			<div class="shell shell--size-2">
				<div class="tabs">
					<div class="tab">
						<div class="assets">
							<div class="asset asset--alt" *ngFor="let H of History">
								<div class="asset__aside">
									<figure class="asset__image">
										<img src="{{H.asset_info[0].thumb ? H.asset_info[0].thumb : CS.default_img}}" alt="{{H.asset_info[0].asset_name}}" width="195" height="110">
									</figure><!-- /.asset__image -->
								</div><!-- /.asset__aside -->

								<div class="asset__main">
									<div class="asset__row">
										<div class="asset__entry">
											<h3>{{H.asset_info[0].asset_name}}</h3>

											<ul class="asset__info-list">
												<li *ngIf="H.asset_info[0].flight_copy">
													<strong>Usage Window: </strong>{{H.asset_info[0].flight_copy}}
												</li>

												<li><strong>Date Downloaded:</strong> {{H.file_downloaded | date:'mediumDate'}}</li>
												
											</ul><!-- /.asset__info-list -->

											<a (click)="reDownload(H)" class="link link--alt">{{H.file_downloaded? 'Re-download' : 'Download'}}</a>
										</div><!-- /.asset__entry -->
									</div><!-- /.asset__row -->
									
									<div class="asset__row">
										<div class="asset__col asset__col--1of2">
											<div class="asset__utils">
												<h4>Affidavit
													<span class="tooltip tooltip--alt ">
														<img src="assets/images/svg/question-mark.svg" alt="">

														<span class="tooltip__content tooltip__content--alt-2">Coming Soon! When your promotional activity is over, come back and submit reporting on how it was used and you may earn points to redeem for incentives in our Rewards Shop</span>
													</span>
												</h4>

												<ul class="asset__info-list" >
													<li><strong>Status: </strong>{{H.submit_status? H.submit_status : 'Awaiting Submission'}}</li>
												</ul><!-- /.asset__info-list -->
											</div><!-- /.asset__utils -->

											<a (click)="openAff(H);" class="btn btn--size-1 btn--disabled">Submit Affidavit</a>
										</div><!-- /.asset__col asset__col-/-1of2 -->

										<div class="asset__col asset__col--1of2">
											<div class="asset__utils">
												<h4>Customization
													<span class="tooltip tooltip--alt" >
														<img src="assets/images/svg/question-mark.svg" alt="">

														<span class="tooltip__content tooltip__content--alt-2">Coming soon! You'll be able to submit asset customizations to your Hallmark Media Rep for pre-approval, and monitor their status.</span>
													</span>
												</h4>

												<a class="btn btn--size-2 btn--disabled"  >Submit Asset for Approval</a>
											</div><!-- /.asset__utils -->
										</div><!-- /.asset__col asset__col-/-1of2 -->
									</div><!-- /.asset__row -->
								</div><!-- /.asset__main -->
							</div><!-- /.asset asset-/-alt -->

						</div><!-- /.assets -->
					</div><!-- /.tab -->
				</div><!-- /.tabs -->
			</div><!-- /.shell -->
		</section><!-- /.section-tabs -->

	<!--								
												<ul class="asset__info-list">
													<li><strong>Status:</strong> Affidavit Pending Approval<span class="asset__status is-pending"></span></li>

													<li><strong>Date Submitted: </strong>March 23, 2022</li>
												</ul><!-- /.asset__info-list -->
												
	<!--											<ul class="asset__info-list">
													<li><strong>Date Submitted:</strong> March 29, 2022</li>
												</ul><!-- /.asset__info-list -->
	<!--
												<ul class="asset__info-list">
													<li><strong>Status:</strong> Affidavit Approved <span class="asset__status is-success"></span></li>

													<li><strong>Date Submitted: </strong>March 23, 2022</li>
												</ul><!-- /.asset__info-list -->
	
<!--											<ul class="asset__info-list">
													<li><strong>Date Submitted:</strong> March 29, 2022</li>
												</ul><!-- /.asset__info-list -->

	<!--											<a href="#" class="link link--alt">Resubmit Asset</a>
												
												<ul class="asset__info-list">
													<li><strong>Status:</strong> Affidavit Rejected <a href="#" class="link link--alt asset__status asset__status--link">Resubmit</a></li>
												</ul><!-- /.asset__info-list -->
	<!--											
												<ul class="asset__info-list">
													<li><strong>Date Submitted:</strong> March 29, 2022</li>
												</ul><!-- /.asset__info-list -->

	<!--											<a href="#" class="btn btn--size-2">Submit Asset for Approval</a>

												<ul class="asset__info-list">
													<li><strong>Status:</strong> Flight window begins ‘x’ date.</li>
												</ul><!-- /.asset__info-list -->


