import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-submit-results',
  templateUrl: './submit-results.component.html',
  styleUrls: ['./submit-results.component.css']
})
export class SubmitResultsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
