<!-- MAKE SURE <footer> has class .footer--secondary -->
<app-contactus *ngIf="router.url!=='/home'"></app-contactus>
<footer class="footer footer--secondary">
    <div class="shell">
        <div class="footer__inner">
            <div class="footer__cols">
                <div class="footer__col">
                    <a routerLink="/home" class="footer__logo">
                       <img src="assets/images/logo--small@2x.png" alt="" width="87" height="36">
					
                    </a>
					
					
					
					
					
                </div>

                <div class="footer__col">
                    <h6>Hallmark Media Hub</h6>
                    <nav class="footer__nav">
                        <ul>
   <!--                         <li>
                                <a routerlink="/library/guideline">Asset Guides</a>
                            </li>
                            <li>
                                <a routerlink="/faqs">FAQS</a>
                            </li>     
  -->                          <li>
                                <a href="https://www.crownmediapress.com/?SiteID=142&NodeID=144" target="_blank">Press</a>
                            </li>
                        </ul>

                    </nav>
                </div>

                <div class="footer__col">
                    <h6>Hallmark Network Sites</h6>

                    <nav class="footer__nav">
                        <ul>
                            <li>
                                <a href="https://www.hallmarkchannel.com/" target="_blank">Hallmark Channel</a>
                            </li>
                            <li>
                                <a href="https://www.hallmarkmoviesandmysteries.com/" target="_blank">Hallmark Movies & Mysteries</a>
                            </li>
                            <li>
                                <a href="https://www.hallmarkdrama.com/" target="_blank">Hallmark Drama</a>
                            </li>
                            <li>
                                <a href="https://www.hmnow.com/" target="_blank">Hallmark Movies Now</a>
                            </li>          
                        </ul>
                    </nav>
                </div>
                <div class="footer__col">
                    <h6>Hallmark Brands</h6>

                    <nav class="footer__nav">
                        <ul>
                            <li>
                                <a href="https://www.hallmark.com/" target="_blank">Hallmark Cards</a>
                            </li>
                            <li>
                                <a href="https://www.crayola.com/" target="_blank">Crayola</a>
                            </li>
                            <li>
                                <a href="https://www.dayspring.com/" target="_blank">Dayspring</a>
                            </li>  
							
                            <li>
                                <a href="https://www.hallmarkchannel.com/hallmark-publishing" target="_blank">Hallmark Publishing</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="footer__col">
                    <h6>Support</h6>
                    <nav class="footer__nav">
                        <ul>
                            <li>
                                <a href="https://www.hallmarkchannel.com/privacy-policy" target="_blank">Privacy Policy	</a>
                            </li>			
                            <li>
                                <a href="https://www.hallmarkchannel.com/terms-of-use" target="_blank">Terms of Use</a>
                            </li>
                            <li>
                                <a href="https://www.hallmarkchannel.com/ca-privacy-policy" target="_blank">CA Privacy Notice</a>
                            </li>			
                            <li>
                                <a href="https://www.hallmarkchannel.com/do-not-sell-my-info" target="_blank">CA Do Not Sell My Info</a>
                            </li>
                            <li>
                                <a href="https://www.hallmarkchannel.com/accessibility-notice" target="_blank">Accessibility</a>
                            </li>       
                        </ul>
                    </nav>

                </div>

                    <p>Copyright © 2022 Hallmark Media, all rights reserved.</p>
            </div>
        </div>
    </div>
</footer>





				
		
			
			
			
		
			
		
		
			