import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

//Services
import { ErrorhandlerService } from './errorhandler.service';
import { ProfileService } from './profile.service';

//Environment
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AssetsService {
	private apiUrl = environment.apiUrl; 
	public assetsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	previewOpen=false;
	previewAsset=null;
	previewName=null;
	previewVidFile=null;
	downloadSuccess=false;
	assetSearch=null;
	current_network=null;
	current_campaign=null;
	current_type=null;
	current_title=null;
	current_tag=null;
	current_sort="asset_start";
	public historyObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public queueObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
		
	

  constructor(private http: HttpClient,
	 private location: Location,
	 private PS : ProfileService,
	 private errorhandlerService: ErrorhandlerService) { }
	
	updateAssets(id){
		if(id>0){
			this.pullHistory(id).subscribe((response) => {
				this.processHistory(response);
			});
			this.pullAssetsById(id).subscribe((response) => {
				this.assetsObj.next(response);
			});
		}else if(this.PS.profile_id){
			this.pullHistory(this.PS.profile_id).subscribe((response) => {
				this.processHistory(response);
			});
			this.pullAssetsById(this.PS.profile_id).subscribe((response) => {
				this.assetsObj.next(response);
			});
		}else{
			this.historyObj.next([]);
			this.queueObj.next([]);			
			this.pullAssets().subscribe((response) => {
				this.assetsObj.next(response);
			});
		}
	}
	closeVid(){
		this.previewOpen=false;
		this.previewAsset=null;
		this.previewName=null;
		this.previewVidFile=null;	
	}
	getAssets(){return this.assetsObj;}
	getQueue(){return this.queueObj;}
	getHistory(){return this.historyObj;}

	processHistory(data){
		var qarr=[];
		var harr=[];
		data.forEach(function(obj){
      		if(obj.status == "IN-QUEUE"){
        		qarr.push(obj);
      		}else if(obj.status == "DOWNLOADED"){
        		harr.push(obj);
      		}
		});
		this.historyObj.next(harr);
		this.queueObj.next(qarr);
	}
	pullAssets():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'assets').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullAssetsById(id):Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'assets/'+id).pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	
	pullHistory(id):Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'history/'+id).pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	addtoQueue(id,json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'addtoqueue/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	reDownloadAsset(id,json_data):Observable<any>{
		return this.http.put<any[]>(this.apiUrl + 'reload/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	removefromQueue(id,json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'removefromqueue/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	checkoutQueue(id,json_data):Observable<any>{
		return this.http.put<any[]>(this.apiUrl + 'checkoutqueue/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	

	
}
