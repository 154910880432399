import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


//Services etc
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
	private ngUnsubscribe = new Subject<void>();
	current_status="pending";
	Users: any |[];
	Profile:any |[];
	role:any |[];
  constructor(public PS: ProfileService, public ADM: AdminService, public router: Router){ }

  ngOnInit(): void {
	  this.PS.getRole().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.role = data; 
		  if(this.role!=='admin'){
			  this.router.navigate(['/home']);
		  }

	  });
	  this.ADM.updateAdmin();
	    //this.Profile=this.PS.profileObj.getValue();
	   this.ADM.getUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Users = data; console.log('users', this.Users);
	  });
	 
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
}
