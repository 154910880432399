import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
	private ngUnsubscribe = new Subject<void>();
	currentStep=1;
	step_submitted=false;
	showPassword=false;
	verifying=false;
	registration={
		role:'affiliate',
		first_name:null,
		last_name:null,
		phone:null,
		email:null,
		title:null,
		password:null,
		repeat:null,
		partner_id:null,
		partner_full: null,
		account_type: null,
		goldrewards: false,
		terms:true,
		optin:true
	};
	pwnumb=false;
	pwchar=false;
	pwsymb=false;
	pwcap=false;
	current_partner=null;
	current_type=null;
	select_open_partner=false;
	select_open_type=false;
	partnerList: any[] = [];
	lastkeydown1: number = 0;

	Partners: any |[];
	partnerlist: any |[];
	PartnersFull: any |[];
  constructor(public PS: ProfileService, public CS:ContentManagerService, public router:Router) { }

  ngOnInit(): void {
	  this.CS.getPartners().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
		  this.Partners = data;
		  this.makeList();
	  });
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
	keyPressNumbers(event) {
		var charCode = (event.which) ? event.which : event.keyCode;
		// Only Numbers 0-9
		if ((charCode < 48 || charCode > 57)) {
		  event.preventDefault();
		  return false;
		} else {
		  return true;
		}
	  }	
	
	getPartnerFulls($event){
		console.log(this.PartnersFull.length);

		let entry = (<HTMLInputElement>document.getElementById('partner_name')).value;
    	this.partnerList = [];
		console.log(entry);

		if (entry.length > 2) {
		  if ($event.timeStamp - this.lastkeydown1 > 200) {
			this.partnerList = this.searchFromArray(this.PartnersFull, entry);
			  console.log(this.partnerList.length);
		  }
		}
	}
	
	 searchFromArray(arr, regex) {
		 
		let matches = [], i;
		for (i = 0; i < arr.length; i++) {
			if(arr[i].toLowerCase().includes(regex.toLowerCase())){
//		  if (arr[i].match(regex)) {
			matches.push(arr[i]);
			  console.log('match');
		  }else{
			  //console.log('nomatch');
		  }
			console.log(matches);
		}
    return matches;
  };
	makeList(){
		var arr=[];
		this.Partners.forEach(function(obj){
			arr.push(obj.partner_full);
		});
		this.PartnersFull=arr;
	}
	passwordCheck(e){
		//var charCode = (event.which) ? event.which : event.keyCode;
		  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		this.pwsymb=specialChars.test(this.registration.password);  
		this.pwnumb=/\d/.test(this.registration.password);
		this.pwchar=this.registration.password==null? false : this.registration.password?.length>=8? true : false;
		this.pwcap=/[A-Z]/.test(this.registration.password);
		
	}
	verifyPw(){
		if(this.pwsymb && this.pwnumb && this.pwchar && this.pwcap && (this.registration.password==this.registration.repeat)){
			return true;
		}else{
			return false;
		}
		
	}
	resumeEmail(){
		this.registration.email=null;
		this.currentStep=1;
	}
	checkEmail(){
		var json_data = [];
		json_data.push({email: this.registration.email});
			
		this.PS.checkRegister(json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			if(data=="registered"){
				this.currentStep=5;
			}
		});
	}

	clearForm(){
		this.router.navigate(['/home']);
	}
	stepBack(){
		if(this.currentStep==1){
			this.clearForm();
		}else if(this.currentStep>4){
			this.resumeEmail();
		}else{
			this.currentStep=this.currentStep-1;
		}
		
	}
	getPartnerID(){
		var that=this;
		this.registration.partner_id=0;
		this.Partners.forEach(function(obj){
			if(obj.partner_full==that.registration.partner_full){
				that.registration.partner_id=obj.partner_id;
			}
		});
	}
	continue(form){
		if(this.currentStep==3){
			this.getPartnerID();
			var json_data = [this.registration];
			
			this.PS.registerUser(json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
				var errcheck = JSON.stringify(data);
				console.log(data);
				if(errcheck.includes('success')){
					console.log('yes', errcheck);
					this.currentStep=4;
				}else{
					console.log('no', errcheck);
					this.currentStep=6;
				}
			})

		
	
		}else if(this.currentStep==1){
			this.step_submitted=true;
			//check vlaid
			console.log(form);
			if(form.valid && this.verifyPw()){
				this.step_submitted=false;
				this.currentStep++;
			}
		}else if(this.currentStep==2){
			this.step_submitted=true;
			if(this.registration.partner_full!==null && this.registration.account_type!==null ){
				this.step_submitted=false;
				this.currentStep++;				
			}
		}else{
			this.currentStep++;
		}
	}
	
	toggleSelectOpen(el){
		this['select_open_'+el]=!this['select_open_'+el];
	}
	select_option(el,val){
		this['select_open_'+el]=!this['select_open_'+el];
		if(el=="partner"){
			console.log('partner is', val);
			this['current_'+el]=val.partner_full;
			this.registration.partner_id=val.partner_id;
			this.registration.partner_full=val.partner_full;		
		}else{
			this['current_'+el]=val;
			if(el=="type"){
				this.registration.account_type=val;
			}
		}
	}


}
