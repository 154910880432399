import { Component, OnInit, Output, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from "@angular/router";

//Services etc
import { ProfileService } from './services/profile.service';
import { ContentManagerService } from './services/content-manager.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'hall-asset-mgr';
	currenttab = '';
	fullscreen=false;
	constructor(public location: Location, public router: Router, public PS: ProfileService, public CS:ContentManagerService) {
		router.events.subscribe(val => {
		 this.currenttab=location.path();
		 this.fullscreen=(this.currenttab=="/register" ? true :false);	 
		});
		this.CS.updateContent();
		
	}
}