<div class="modal welcome-modal " [ngClass]="{ 'is-open': CS.vidOpen }">
    <div class="modal__inner">
        <header class="form__head">
            <img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/HallmarkMedia_Logo_White.png" alt="Welcome to the Hallmark Media Hub" width="116" height="48">
            <h3 >Welcome to<br>the Hallmark Media Hub</h3>
        </header>
 		<video width="100%" height="auto" controls="" class="still-img" *ngIf="CS.vidOpen">
            <source src="https://ik.imagekit.io/hallmedia/HallmarkMediaHubWelcome_PN419706.mp4" type="video/mp4" autoplay> Your browser does not support this video preview.
        </video>
        
        <a class="btn" (click)="CS.closeWelcome()" >Let's Get Started</a>
        <a (click)="CS.closeWelcome()" class="modal__btn">
            <img src="assets/images/svg/exit-button.svg" alt="">
        </a>
    </div>
</div>