import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

//Services
import { ErrorhandlerService } from './errorhandler.service';
import { ProfileService } from './profile.service';

//Environment
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AdminService {
	private apiUrl = environment.apiUrl; 
	public assetsAdminObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public usersAdminObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
		
	

  constructor(private http: HttpClient,
	 private location: Location,
	 private PS : ProfileService,
	 private errorhandlerService: ErrorhandlerService) { }
	
	updateAdmin(){
		this.pullUsers().subscribe((response) => {
			this.usersAdminObj.next(response);
		});
	}
	getUsers(){return this.usersAdminObj;}
	pullUsers():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'usersforadmin').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	addtoQueue(id,json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'addtoqueue/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	
	

	
}
