
        <section class="section-headline section-headline--tertiary">
            <div class="shell shell--size-3">
                <h2>Users Overview</h2>
            </div><!-- /.shell shell-/-size-3 -->
        </section><!-- /.section-headline -->

        <section class="section-filters section-filters--bottom-offset">
            <div class="shell">
                <h3>Filters:</h3>

                <div class="cols cols--alt cols--bottom-offset">
                    <div class="col col--1of5">
                        <div class="select">
                            <div class="select__head">
                                <p>By Network</p>
                            </div><!-- /.select__head -->

                            <div class="select__list">
                                <ul>
                                    <li>Pending</li>

                                    <li>Approved</li>

                                    <li>Denied</li>
                                </ul>
                            </div><!-- /.select__list -->
                        </div><!-- /.select -->
                    </div><!-- /.col-/-1of5 -->
                </div><!-- /.cols -->
            </div><!-- /.shell -->
        </section><!-- /.section-filters -->
        

        <section class="section-created-assets">
            <div class="shell shell--size-4">
                <div class="section__inner">
                    <div class="section__head">
                        <h4>Manage Users</h4>

                        <a routerLink="/register" class="btn section__btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <rect data-name="icons/add background" width="20" height="20" fill="none"></rect>
                              <rect data-name="Box 24pt" width="20" height="20" fill="none"></rect>
                              <path data-name="Icon Style" d="M7.792,7.5h6.375V9.167H7.792v7.5H6.375v-7.5H0V7.5H6.375V0H7.792Z" transform="translate(1.417 1.667)" fill="currentColor"></path>
                            </svg>

                            <span>
                                Add New User
                            </span>
                        </a>
                    </div><!-- /.section__head -->

                    <div class="section__body">
                        <div class="table-assets">
                            <table>
                                <colgroup>
                                    <col class="table__col--size-1">

                                    <col class="table__col--size-2 table__col--center">

                                    <col class="table__col--size-3">

                                    <col class="table__col--size-4">

                                    <col class="table__col--size-5">

                                    <col class="table__col--size-4">

                                    <col class="table__col--size-6">

                                    <col class="table__col--size-7">

                                    <col class="table__col--size-8">

                                    <col class="table__col--size-9">
                                </colgroup>

                                <thead>
                                    <tr>
                                        <th></th>

                                        <th>
                                            Status

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>

                                        <th>
                                            First Name

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>

                                        <th>
                                            Last Name

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>

                                        <th>
                                            Partner

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>

                                        <th>
                                            Account Type

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>

                                        <th>
                                            Email

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>

                                        <th>
                                            Registered Date

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>

                                        <th>
                                            Approved Date

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>

                                        <th>
                                            Visits

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>

                                        <th>
                                            Downloads

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>

                                        <th>
                                            Last Accessed

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>
										<th>
                                            Gold Rewards Status

                                            <img src="assets/images/svg/sort.svg" alt="">
                                        </th>

                                    </tr>
                                </thead>
                                
                                <tbody>
									<ng-container *ngFor="let user of Users | statusFilter: 'status': current_status">
										<tr class="is-active">
											<td>
												<a href="#" class="btn btn--edit">
													<svg id="Admin_no_text" data-name="Admin no text" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="currentColor">
														<g id="Edit" transform="translate(9 9)">
															<path id="Shape" d="M.379,16H.374A.37.37,0,0,1,.11,15.89.375.375,0,0,1,0,15.62L.042,12a.375.375,0,0,1,.109-.261L11.782.11a.374.374,0,0,1,.53,0l3.577,3.578A.384.384,0,0,1,16,3.955a.372.372,0,0,1-.11.265L4.261,15.847A.372.372,0,0,1,4,15.956ZM9.66,3.292h0L.79,12.161.754,15.244l3.083-.035,8.87-8.869L9.66,3.292ZM12.046.906h0L10.188,2.762,13.236,5.81l1.858-1.856L12.046.906Z" transform="translate(1 0)"></path>
														</g>
													</svg>
												</a>
											</td>

											<td class="table__cell--center">Active</td>

											<td>
												<a href="#">Risus Viverra Adipiscing - Spring 2021</a>

												<span class="table__tooltip">
													<svg id="Tooltip_Ellipses" data-name="Tooltip Ellipses" xmlns="http://www.w3.org/2000/svg" width="24" height="9" viewBox="0 0 24 9">
														<rect id="Rectangle" width="24" height="9" rx="4.5" fill="currentColor"></rect>
														<circle id="Oval" cx="1.5" cy="1.5" r="1.5" transform="translate(6 3)" fill="#fff"></circle>
														<circle id="Oval-2" data-name="Oval" cx="1.5" cy="1.5" r="1.5" transform="translate(10.5 3)" fill="#fff"></circle>
														<circle id="Oval-3" data-name="Oval" cx="1.5" cy="1.5" r="1.5" transform="translate(15 3)" fill="#fff"></circle>
													</svg>
												</span>
											</td>

											<td>The Hallmark Channel</td>

											<td>Summer Nights- All Month Long</td>

											<td>Moonlight in Vermont</td>

											<td>03/31/2022- 09/30/2023</td>

											<td>Cell 1</td>

											<td>Cell 2</td>

											<td>Cell 3</td>

											<td>Cell 1</td>

											<td>Cell 2</td>

											<td>Cell 3</td>
										</tr>
									</ng-container>
                                </tbody>
                            </table>
                        </div><!-- /.table-assets -->          
                    </div><!-- /.section__body -->
                </div><!-- /.section__inner -->
            </div><!-- /.shell shell--size-3 -->
        </section><!-- /.section-created-assets -->

