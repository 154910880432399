import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { AuthService } from './services/auth.service';
import { AuthService as AuthGuard } from './services/auth.service';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { NetworkComponent } from './components/network/network.component';
import { AssetLibraryComponent } from './components/asset-library/asset-library.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { MyActivityComponent } from './components/my-activity/my-activity.component';
import { AssetQueueComponent } from './components/asset-queue/asset-queue.component';
import { UsersComponent } from './admin/users/users.component';
import { AssetsComponent } from './admin/assets/assets.component';
import { ContentComponent } from './admin/content/content.component';




const routes: Routes = [
	{ path: '', redirectTo: '/home', pathMatch: 'full'},
	{ path: 'login', redirectTo: '/home/login', pathMatch: 'full' },
	{ path: 'logout', redirectTo: '/home/logout', pathMatch: 'full' },
	{ path: 'home', component: LandingComponent },
	{ path: 'home/:loginstate', component: LandingComponent },
	{ path: 'register', component: RegisterComponent},
	{ path: 'network/:ntwk', component: NetworkComponent, canActivate: [AuthGuard]},
	{ path: 'network',  redirectTo: '/network/hc', pathMatch: 'full'},
	{ path: 'library/:id/:criteria', component: AssetLibraryComponent, canActivate: [AuthGuard] },
	{ path: 'library', component: AssetLibraryComponent, canActivate: [AuthGuard]},
	{ path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuard]},
	{ path: 'my-activity', component: MyActivityComponent, canActivate: [AuthGuard]},
	{ path: 'asset-queue/:id', component: AssetQueueComponent, canActivate: [AuthGuard]},
	{ path: 'asset-queue', component: AssetQueueComponent,canActivate: [AuthGuard]},
	{ path: 'admin/users/:id/', component: UsersComponent,canActivate: [AuthGuard]},
	{ path: 'admin/users', component: UsersComponent,canActivate: [AuthGuard]},
	{ path: 'admin/assets/:id/', component: AssetsComponent,canActivate: [AuthGuard]},
	{ path: 'admin/asstes', component: AssetsComponent,canActivate: [AuthGuard]},
	{ path: 'admin/content/:id/', component: ContentComponent,canActivate: [AuthGuard]},
	{ path: 'admin/content', component: ContentComponent,canActivate: [AuthGuard]},
	{ path: '**', redirectTo: '/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
