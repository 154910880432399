<div class="modal" [ngClass]="{ 'is-open': AS.downloadSuccess }">
	<div class="modal__inner modal--size-1">
		<div class="modal__entry">
			<figure class="modal__image">
				<img src="assets/images/svg/assets.svg" alt="">
			</figure><!-- /.modal__image -->

			<h2>Congrats!</h2>
			<h3>You can now download these individual assets anytime, at your convenience, from the links on your <a (click)="AS.downloadSuccess=false;" class="link">MY ACTIVITY</a> page.</h3>

			<span class="modal__info">Don't forget to come back later and let us know all about how these assets were used to promote Hallmark Media programming and start earning incentives.</span>
		</div><!-- /.modal__entry -->

		<div class="modal__actions">
			<a (click)="AS.downloadSuccess=false;" class="btn btn--size-3">My Activity</a>

			<a routerLink="/library" class="link">Back to Library</a>
		</div><!-- /.modal__actions -->

		<a (click)="AS.downloadSuccess=false;" class="modal__btn">
			<img src="assets/images/svg/exit-button.svg" alt="">
		</a>
	</div><!-- /.modal__inner -->
</div><!-- /.modal -->

