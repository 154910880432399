<section class="section-callout">
	<div class="shell">
		<div class="callout">
			<div class="callout__entry">
				<p class="support">
					Need some help? Please reach out with any questions or issues: <a href="mailto:support@hallmarkmediahub.com" class="link">support@hallmarkmediahub.com</a>.
				</p>
				<p><i>Please note that access to this website is exclusively for our affiliate partners. If you have press inquiries, please visit our press site at <a href="https://crownmediapress.com" target="_blank">crownmediapress.com</a></i></p>
			</div><!-- /.callout__entry -->
		</div><!-- /.callout -->
	</div><!-- /.shell -->
</section><!-- /.section-callout -->