import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';



@Component({
  selector: 'app-my-activity',
  templateUrl: './my-activity.component.html',
  styleUrls: ['./my-activity.component.css']
})
export class MyActivityComponent implements OnInit {
	private ngUnsubscribe = new Subject<void>();
	current_tab="history";
	History:any|[];
	Customizations: any |[];
	Assets:any|[];
	Profile: any |[];
	affid_status=["No Affidavit Submitted", 
		"Affidavit Pending Approval <span class='asset__status is-pending'></span>",
		"Affidavit Approved <span class='asset__status is-success'></span>",
		"Affidavit Rejected <a href='' class='link link--alt asset__status asset__status--link'>Resubmit</a>"
	]

  constructor(public PS:ProfileService, public AS: AssetsService, public CS: ContentManagerService, public router:Router) { }

  ngOnInit(): void {
	  this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Profile = data;});
	  this.AS.updateAssets(0);
	  this.AS.getAssets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Assets=data;});	
	  this.AS.getHistory().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.History=data; console.log('history', this.History);
	  });
	  
  }
	ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	reDownload(H){
		var json_data=[];
		var url= H.asset_info[0].path;
		json_data.push({profile_id:this.PS.profile_id});
		this.AS.reDownloadAsset(H.history_id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			   if(data !== "success"){
				   this.CS.alert_message = "This submission encountered an error.  Please try again or contact customer support";
				   this.CS.showAlert = true;
			   }else{
				   window.open( url, "_blank");
			   }
		   });
	}
	toggleTool( H, el){
		if(!H['tool_'+el]){
			H['tool_'+el]=true;
		}else{
			H['tool_'+el]=false;
		}
	}
}
