<app-header-full></app-header-full>
<section class="section-form">
	<div #step1 class="shell shell--size-1">
		
		<a (click)="stepBack();" class="link section__link">{{ currentStep>1? 'Previous':'Cancel'}}</a>

		<div class="bar-progress">
			<span class="progress__line" [style.width.%]="currentStep==2? '39': currentStep==3? '68': currentStep==4 ? '100':19.5 "></span>
		</div><!-- /.bar-progress -->

		<div class="form" [ngClass]="currentStep>3 ? 'form--alt-2': currentStep>2 ? 'form--alt':''">
			<form name="enrollForm" #enrollForm="ngForm" novalidate [ngClass]="{'submitted':step_submitted}">
				
								
			<div *ngIf="currentStep==1">
				<header class="form__head">
					<h2>Let’s get you registered!</h2>

					<h3>Sign up below to get access to programming resources, promotional assets, information and more! </h3>
				</header><!-- /.form__head -->

				<div class="form__body">
					<div class="form__row">
						<label for="field-first_name" class="form__label">First Name</label>

						<div class="form__controls">
							<input type="text" class="field" name="field-first_name" id="field-first_name"  
								   required 
								   [(ngModel)]="registration.first_name" 
								   #first_name="ngModel">
							<p class="form__error" *ngIf="first_name.invalid && step_submitted">Please enter your first name.</p><!-- /.form__error -->

						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row">
						<label for="field-last_name" class="form__label">Last Name</label>

						<div class="form__controls">
							<input type="text" class="field" name="field-last_name" id="field-last_name"   
								   required 
								   [(ngModel)]="registration.last_name" 
								   #last_name="ngModel">
							<p class="form__error" *ngIf="last_name.invalid && step_submitted">Please enter your last name.</p><!-- /.form__error -->
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row">
						<label for="field__phone" class="form__label">Phone </label>

						<div class="form__controls">
							<input type="text"  (keypress)="keyPressNumbers($event)" minlength="10" class="field" name="field__phone" id="field__phone"    
								   [(ngModel)]="registration.phone" 
								   #phone="ngModel" required>
							<p class="form__error"  *ngIf="phone.invalid  && step_submitted">Please enter a 10-digit phone number.</p><!-- /.form__error -->
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row">
						<label for="field-email" class="form__label">Email Address</label>

						<div class="form__controls">
							<input type="text" email class="field" name="field-email" id="field-email"   
								   required 
								   [(ngModel)]="registration.email" 
								   #email="ngModel"
								   (change)="checkEmail()"
								   >
							<p class="form__error" *ngIf="email.invalid && step_submitted">Please enter a valid email address.</p><!-- /.form__error -->
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row">
						<label for="field-password" class="form__label">Password</label>

						<div class="form__controls">
							<input [type]="showPassword ? 'text' : 'password'" class="field" name="field-password" id="field-password"  [(ngModel)]="registration.password" #password="ngModel" (keyup)="passwordCheck($event)"
								   [ngClass]="{'pw_error':(!pwnumb || !pwcap || !pwsymb || !pwchar) && step_submitted}"
								   >
							<p class="form__error" *ngIf="(!pwnumb || !pwcap || !pwsymb || !pwchar) && step_submitted">Please enter a valid password</p><!-- /.form__error -->

							<div class="form__hints">
								<span class="form__hint" [ngClass]="password.dirty && pwchar ? 'on-success':'on-error'" >8 characters</span>

								<span class="form__hint" [ngClass]="password.dirty && pwsymb ? 'on-success':'on-error'">1 symbol</span>

								<span class="form__hint" [ngClass]="password.dirty && pwnumb? 'on-success':'on-error'">1 number</span>

								<span class="form__hint" [ngClass]="password.dirty && pwcap? 'on-success':'on-error'">1 capital letter</span>
							</div><!-- /.form__hints -->
							<a (click)="showPassword=!showPassword;" class="form__filter is-on">{{ showPassword ? 'Hide' : 'Show'}}</a>
							
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row" *ngIf="pwnumb && pwcap && pwsymb && pwchar">
						<label for="field-password_repeat" class="form__label">Repeat Password</label>

						<div class="form__controls">
							<input [type]="showPassword ? 'text' : 'password'" class="field on-focus" name="field-password_repeat" id="field-password_repeat" (change)="verifying=true" (focus)="verifying=false" [(ngModel)]="registration.repeat" #repeat="ngModel" [ngClass]="{'pw_error':verifying && registration.repeat!==registration.password}">
							<p class="form__error" *ngIf="verifying && registration.repeat!==registration.password">Your password entries do not match.</p><!-- /.form__error -->

							<a (click)="showPassword=!showPassword;" class="form__filter is-on">{{ showPassword ? 'Hide' : 'Show'}}</a>
							
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__terms">
						<div class="checkbox">
							<input type="checkbox" name="checkbox_terms" id="checkbox_terms" [checked]="registration.terms" [(ngModel)]="registration.terms"
								   #terms="ngModel" required>

							<label for="checkbox_terms">I agree to the <a href="https://www.hallmarkchannel.com/terms-of-use" target="_blank">Terms and conditions</a>.</label>
							<p class="form__error" *ngIf="!registration.terms && step_submitted">You must agree to the terms.</p>
						</div><!-- /.checkbox -->

						<!--<div class="checkbox">
							<input type="checkbox" id="checkbox_logged" checked="checked">

							<label for="checkbox_logged">Keep me logged in.</label>
						</div><!-- /.checkbox -->

						<div class="checkbox">
							<input type="checkbox" id="checkbox_subscription" [checked]="registration.optin" #optin="ngModel"[(ngModel)]="registration.optin" name="checkbox_subscription">

							<label for="checkbox_subscription">Yes, sign me up for Partnership Marketing Notifications.</label>
						</div><!-- /.checkbox -->	

						<p>By clicking 'Submit' you agree to the <a href="https://www.hallmarkchannel.com/terms-of-use" target="_blank">Terms and Conditions</a>, and <a href="https://www.hallmarkchannel.com/privacy-policy" target="_blank">Privacy Policy</a>.</p>
					</div><!-- /.form__terms -->
				</div><!-- /.form__body -->
				
			</div>
			<div *ngIf="currentStep==2">	
				<header class="form__head">
					<h2>Tell us more about you.</h2>

					<h3>Please answer the questions below to complete enrollment request.</h3>
				</header><!-- /.form__head -->

				<div class="form__body">
					<div class="form__row">
						<label for="field-title" class="form__label">Your Title</label>

						<div class="form__controls">
							<input type="text" class="field" name="field-title" id="field-title" [(ngModel)]="registration.title">
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row">
						<label for="select-mvpd" class="form__label">Partner Name</label>
						<input type="text" class="field" name="field-title"  [(ngModel)]="registration.partner_full"
							   (keyup)="getPartnerFulls($event)" id="partner_name" list="partner_list"
							   [ngClass]="{'pw_error': !registration.partner_full  && step_submitted}"
							   
							   />
						<datalist id="partner_list">
						   <option *ngFor="let partner of partnerList" [value]="partner">{{partner}}</option>
						</datalist>
						<p class="form__error" *ngIf="step_submitted && registration.partner_full==null">Please enter your company name.</p>
						
					</div><!-- /.form__row -->
					
					<div class="form__row up-1">
						<label for="select-type" class="form__label">Account Type</label>

						<div class="form__controls">
							<div class="select select--gray" id="select-typ" [ngClass]="{'is-open':select_open_type, 'select_error': step_submitted && registration.account_type==null}"
					 (click)="toggleSelectOpen('type');" >
								<div class="select__head">
									<p>{{ current_type ?  current_type  : "Select" }}</p>
								</div><!-- /.select__head -->

								<div class="select__list" (mouseleave)="toggleSelectOpen('type');">
									<ul>
										<li (click)="select_option('type','Local Ad Sales')">Local Ad Sales</li>
										<li (click)="select_option('type','Marketing')">Marketing</li>
										<li (click)="select_option('type','National')">National</li>
										<li (click)="select_option('type','Enterprise')">Enterprise</li>
									</ul>
								</div><!-- /.select__list -->	
							</div><!-- /.select -->
							<p class="form__error" *ngIf="step_submitted && registration.account_type==null">Please select.</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
				</div><!-- /.form__body -->
			</div>
			<div  *ngIf="currentStep==3">
				<header class="form__head">
					<h2>Enroll in Hallmark Media Gold Rewards and Receive incentives.</h2>

<!--					<a (click)="skipGold();" class="link">Skip and take me to the Asset Library</a>-->

					<h3>Enrolling is fast, free and easy!<br>Earn points and redeem prizes for the promotions you are running!</h3>
				</header><!-- /.form__head -->

				<div class="form__body">
					<div class="form__terms terms__3">
						<div class="checkbox">
							<input type="checkbox" id="checkbox_incentive" [(ngModel)]="registration.goldrewards">

							<label for="checkbox_incentive">Yes, I want to enroll in Hallmark Media Gold Rewards.</label>
						</div><!-- /.checkbox -->
					</div><!-- /.form__terms -->
					<!--<div class="form__radios">
						<div class="radio">
							<input type="radio" id="radio_group_yes" name="radio_group" value="yes" checked="checked" />

							<label for="radio_group_yes">Yes, I want to enroll in Gold Rewards</label>
						</div><!-- /.radio -->


					<!--</div><!-- /.form__radios -->
				</div><!-- /.form__body -->

			</div>
			<div  *ngIf="currentStep==4">
				<header class="form__head">
					<h2>All set!</h2>

					<h3>Your request for enrollment in the Hallmark Media Hub has been sent. You will be notified via email and within your account once approved. Please allow 24-48 hours for approval. 
					</h3>
				</header><!-- /.form__head -->

			</div>
			<div  *ngIf="currentStep==5">
				<header class="form__head">
					<h2>A registration already exists for {{registration.email}}</h2>

					<h3>Please go to the <a routerLink="/login" class="link alert__link">LOGIN</a> page or go back to <a (click)="resumeEmail()" class="link alert__link">REGISTER</a> with a different email address
					</h3>
				</header><!-- /.form__head -->
			</div>
			<div  *ngIf="currentStep==6">
				<header class="form__head">
					<h2>A submission error occured.</h2>

					<h3>Please <a (click)="resumeEmail()" class="link alert__link">TRY AGAIN</a> or reach out to <a href="mailto:info@hallmarkmediahub.com" class="link alert__link">CUSTOMER SUPPORT</a> for assistance
					</h3>
				</header><!-- /.form__head -->
			</div>

				

				<div class="form__actions form__actions--alt" *ngIf="currentStep<4">
					<button class="btn btn--alt btn--spacing-1" (click)="clearForm();">Cancel</button>

					<a (click)="continue(enrollForm.form);" class="btn form__btn btn--spacing-1 btn--size-5"  name="register_continue">{{currentStep>2 ? 'Complete':'Continue'}}</a>
				</div><!-- /.form__actions -->
				
				<div class="form__actions form__actions--alt" *ngIf="currentStep==4">
					<a routerLink="/home" class="btn btn--alt btn--spacing-1 btn--size-6">Go to Main Page</a>

<!--					<a routerLink="/library" class="btn form__btn btn--spacing-1 btn--size-6">Go to Asset Library</a>-->
				</div><!-- /.form__actions -->

				
				
			</form>
		</div><!-- /.form -->
	</div><!-- /.shell shell-/-size-1 -->
	

</section><!-- /.section-form -->

