<div *ngFor="let network of Networks | statusFilter: 'network_short': ntwk | statusFilter: 'detail': '1'" >
	<div class="hero hero--secondary" >
		<div class="shell">
			<div class="hero__image" style="background-image: url(assets/images/temp/hero-img-1.jpg);"></div><!-- /.hero__image -->

			<div class="hero__logo">
				<img src="{{network.network_logo}}" alt="{{ntwk}}-{{network.network_full}}">	
			</div><!-- /.hero__logo -->

			<div class="hero__inner">

				<swiper class="swiper slider-cards"  [config]="config">
					
						<ng-template swiperSlide  class="swiper-slide slider__slide" 
						 *ngFor="let item of Content | statusFilter: 'network_id': network.network_id | statusFilter: 'page_feature': '0'">
							<div class="card">
								<div class="card__inner">
									<div class="card__front">
										<figure class="card__background">
											<img src="{{item.artwork}}" alt="{{item.header}}" width="589" height="330">

										<!--	<a *ngIf="item.content_type!=='external'" routerLink="/library/{{item.reference}}/{{item.content_type}}" 
										   class="btn card__btn">{{item.cta? item.cta : 'View Assets'}}</a>
											<a *ngIf="item.content_type=='external'" href="{{item.reference}}" target="_blank" class="btn card__btn">{{item.cta? item.cta : 'Visit Site'}}</a>-->
										</figure><!-- /.card__background -->

									</div><!-- /.card__front -->

									<div class="card__back">
										<div class="card__entry">
										<h2 [innerHtml]="item.headline"></h2>

										<div class="card__info" *ngIf="item.subhead">
											<p [innerHtml]="item.subhead"></p>
										</div><!-- /.card__info -->

											<p [innerHtml]="item.copy"></p>

											<a *ngIf="item.content_type!=='external'" routerLink="/library/{{item.reference}}/{{item.content_type}}" 
											   class="btn  btn--purple card__btn">{{item.cta? item.cta : 'View Assets'}}</a>
											<a *ngIf="item.content_type=='external'" href="{{item.reference}}" target="_blank" class="btn  btn--purple  card__btn">{{item.cta? item.cta : 'Visit Site'}}</a>

										</div><!-- /.card__entry -->
									</div><!-- /.card__back -->
								</div><!-- /.card__inner -->

								<h6>{{item.undertag}}</h6>
							</div><!-- /.card -->
						</ng-template><!-- /.swiper-slide slider__slide -->
					

					

				</swiper><!-- /.swiper -->

			</div><!-- /.hero__inner -->
		</div><!-- /.shell -->
	</div><!-- /.hero -->

	<section class="section section--purple" *ngFor="let item of Content | statusFilter: 'network_id': network.network_id | statusFilter: 'page_feature': '1'">
		<div class="shell">
			<div class="box">
				<div class="grid">
					<div class="grid__col grid__col--size-1" >
						<div class="box__content">
							<h2 [innerHtml]="item.headline"></h2>

							<h4 [innerHtml]="item.subhead"></h4>

							<p [innerHtml]="item.copy"></p>

							<!-- use type addtoqueue to accommodate reference asset-->
							<a *ngIf="item.content_type!=='external'" routerLink="/library/{{item.reference}}/{{item.content_type}}" 
										   class="btn">{{item.cta? item.cta : 'View Assets'}}</a>
							<a *ngIf="item.content_type=='external'" href="{{item.reference}}" target="_blank" class="btn">{{item.cta? item.cta : 'Visit Site'}}</a>
							

						</div><!-- /.box__content -->
					</div><!-- /.grid__col -->

					<div class="grid__col grid__col--size-2">
						<div class="box__image">
							<img src="{{item.artwork ? item.artwork  : network.featured_default}}" alt="{{item.headline}}">
						</div><!-- /.box__image -->
					</div><!-- /.grid__col -->
				</div><!-- /.grid -->
			</div><!-- /.box -->
		</div><!-- /.shell -->
	</section><!-- /.section -->
	
	

	<section class="section section--cards padding-5">
		<div class="shell">
			<div class="section__head">
				<h3>Recently Uploaded Assets</h3>
			</div><!-- /.section__head -->

			<div class="cards-secondary">
				<div class="grid">
					<ng-container *ngFor="let asset of getNetworkAssets(Assets) ;let  i=index">
						<div class="grid__col grid__col--1of4"  *ngIf=" i < 4 ">
							<div class="card-secondary"> 
								<div class="card__image">
									<img src="{{asset.thumb ? asset.thumb : network.thumb_default}}" alt="{{asset.asset_name}}">
								</div><!-- /.card__image -->

								<div class="card__content">
									<p [innerHtml]="asset.asset_name"></p>

									<div class="card__actions">
										<a routerLink="/library/{{asset.asset_id}}/add" class="btn">Add to Queue</a>
									</div><!-- /.card__actions -->
								</div><!-- /.card__content -->
							</div><!-- /.card-secondary -->
						</div><!-- /.grid__col -->
					</ng-container>

				</div><!-- /.grid -->
			</div><!-- /.cards-secondary -->

			<div class="section__actions">
				<a routerLink="/library/{{ntwk}}/network" class="link link--alt">
					View All Recent Assets
				</a>
			</div><!-- /.section__actions -->
		</div><!-- /.shell -->
	</section><!-- /.section -->

	<section class="section section--cards section--spacing-1 padding-11" >
		<div class="shell">
			<div class="section__head">
				<h3>Resources</h3>
			</div><!-- /.section__head -->

			<div class="cards-secondary cards-secondary--spacing-1">
				<div class="grid">
					<div class="grid__col grid__col--1of3" *ngIf="ntwk !=='hmmore' && ntwk !=='hd'">
						<div class="card-secondary">
							<div class="card__content">
								<div class="card__info">
									<span>Brand</span>
								</div><!-- /.card__info -->

								<ul class="card__links">
									<li >
										<a href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/assets/Hallmark+Channel+TVE_VOD+Planner+October+2022.xls" target="_blank" class="link link--alt ">
											<img src="assets/images/svg/ico-document.svg" alt="">
											October Monthly TVE/VOD Planner
										</a>
									</li>
									<li >
										<a href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/assets/Hallmark+Channel+TVE_VOD+Planner+November+2022.xls" target="_blank" class="link link--alt ">
											<img src="assets/images/svg/ico-document.svg" alt="">
											November Monthly TVE/VOD Planner
										</a>
									</li>
									<li >
										<a href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/SocialMediaPosts_November2022.pdf" target="_blank" class="link link--alt ">
											<img src="assets/images/svg/ico-document.svg" alt="">
											November Social Posts
										</a>
									</li>
									<li *ngIf="network.logo_files">
										<a href="{{network.logo_files}}" target="_blank" class="link link--alt ">
											<img src="assets/images/svg/ico-document.svg" alt="">
											{{network.network_full}} Logos
										</a>
									</li>
								</ul><!-- /.card__links -->
							</div><!-- /.card__content -->
						</div><!-- /.card-secondary -->
					</div><!-- /.grid__col -->
					<div class="grid__col grid__col--1of3"  *ngIf="ntwk !=='hmmore' && ntwk !=='hd'">
						<div class="card-secondary">
							<div class="card__content">
								<div class="card__info">
									<span>FCC Certifications</span>
								</div><!-- /.card__info -->

								<ul class="card__links">
									<li>
										<a href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/FCC+Kids+2021.pdf" target="_blank" class="link link--alt">
											<img src="assets/images/svg/ico-document.svg" alt="">

											FCC Children's Programming Certification 
										</a>
									</li>

									<li>
										<a href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/assets/Closed_Captioning_Certification_Q3_2022.pdf" target="_blank" class="link link--alt">
											<img src="assets/images/svg/ico-document.svg" alt="">

											FCC Closed Captioning Q3 2022 
										</a>
									</li>
								</ul><!-- /.card__links -->
							</div><!-- /.card__content -->
						</div><!-- /.card-secondary -->
					</div><!-- /.grid__col -->

					<div class="grid__col grid__col--1of3"  *ngIf="ntwk !=='hmmore' && ntwk !=='hd'">
						<div class="card-secondary">
							<div class="card__content">
								<div class="card__info">
									<span>Technical Information Sheet</span>
								</div><!-- /.card__info -->

								<ul class="card__links">
									<li>
										<a href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/assets/Hallmark+Media+Tech+Sheet_Aug+2022.pdf" target="_blank" class="link link--alt">
											<img src="assets/images/svg/ico-document.svg" alt="">

											Technical Information Sheet
										</a>
									</li>
								</ul><!-- /.card__links -->
							</div><!-- /.card__content -->
						</div><!-- /.card-secondary -->
					</div><!-- /.grid__col -->

					<div class="grid__col grid__col--1of3">
						<div class="card-secondary">
							<div class="card__content">
								<div class="card__info">
									<span>Contact Sheet</span>
								</div><!-- /.card__info -->

								<ul class="card__links">
									<li>
										<a disabled class="link link--alt link--space-3">
											<img src="assets/images/svg/ico-document.svg" alt="">
											Coming Soon

											<!--Crown Media Family Networks Contact Sheet-->
										</a>
									</li>
								</ul><!-- /.card__links -->
							</div><!-- /.card__content -->
						</div><!-- /.card-secondary -->
					</div><!-- /.grid__col -->
				</div><!-- /.grid -->
			</div><!-- /.cards-secondary -->

			</div><!-- /.shell -->
	</section><!-- /.section -->
	
	
</div>